import { type TIMEFRAMES } from './constants'
import { IndicatorCalcValKey, IndicatorKey, IndicatorOptionKey } from './parse-json-util'

export enum InstrumentType {
  indicators = 'indicators',
  overlays = 'overlays',
  other = 'other',
  value = 'value',
}

export enum IndicatorLabel {
  aro = 'AROON',
  bb = 'BB', //'Bollinger Bands',
  ema = 'EMA', //'Exponential Moving Avarage',
  fi = 'FI', //'Force Index',
  macd = 'MACD',
  price = 'Price',
  rsi = 'RSI', //'Relative Strength Index',
  sma = 'SMA', //'Simple Moving Avarage',
  trix = 'TRIX',
  vwap = 'VWAP',
  value = 'Value',
}

export enum IndicatorCalculatedValueLabel {
  aroonUp = 'Aroon Up',
  aroonDown = 'Aroon Down',
  oscillator = 'Oscillator',
  fi = 'Force Index',
  macd = 'MACD',
  signal = 'Signal',
  histogram = 'Histogram',
  trix = 'TRIX',
  rsi = 'Relative Strength Index',
  sma = 'Simple Moving Avarage',
  stdDev = 'Standard Deviation',
  upperBand = 'Upper Band',
  lowerBand = 'Lower Band',
  ema = 'Exponential Moving Avarage',
  vwap = 'VWAP',
  open = 'Open',
  high = 'High',
  low = 'Low',
  close = 'Close',
  value = 'Value',
}

export enum IndicatorOptionLabel {
  period = 'Period',
  deviations = 'Deviations',
  fastPeriod = 'Fast Period',
  signalPeriod = 'Signal Period',
  slowPeriod = 'Slow Period',
}

export interface IIndicator {
  indicator: IndicatorKey
  indicatorPreset: IndicatorOptionKey
  indicatorCalcVal: IndicatorCalcValKey
  timeframe: ReturnType<typeof TIMEFRAMES>[number]['value']
}

export interface IIndicators {
  indicators: IIndicator[]
  overlays: IIndicator[]
  other: IIndicator[]
}

export type CompareTargetComponentValueType = {
  indicator: IIndicator['indicator']
  indicatorPreset: IIndicator['indicatorPreset']
  indicatorCalcVal: IIndicator['indicatorCalcVal']
  value: number | null
  timeframe: ReturnType<typeof TIMEFRAMES>[number]['value'] | null
}

export type CompareTargetValueType = {
  indicator: IIndicator['indicator'] | null
  indicatorPreset: IIndicator['indicatorPreset'] | null
  indicatorCalcVal: IIndicator['indicatorCalcVal'] | null
  value: number | null
  timeframe: ReturnType<typeof TIMEFRAMES>[number]['value'] | null
}

export enum EXTRADAY_TIMEFRAMES {
  d = 'd',
  w = 'w',
  m = 'm',
}

export enum INTRADAY_TIMEFRAMES {
  i1 = 'i1',
  i2 = 'i2',
  i3 = 'i3',
  i5 = 'i5',
  i10 = 'i10',
  i15 = 'i15',
  i30 = 'i30',
  h = 'h',
  h2 = 'h2',
  h4 = 'h4',
}

export enum INTRADAY_FREE_TIMEFRAME {
  intraday = 'intraday',
}

export type TimeframesType = EXTRADAY_TIMEFRAMES | INTRADAY_TIMEFRAMES | INTRADAY_FREE_TIMEFRAME
